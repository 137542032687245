import { isTruthy } from '@/utils/guards';
const minColumnSize = 48;
export function useDataTableResize(columns, props, columnRef, onResizeMove) {
    let cursorX;
    let resizeColumn;
    let nextColumn;
    function resizeStart(event, column) {
        var _a, _b, _c, _d;
        (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        cursorX = event.pageX;
        resizeColumn = column;
        const frameWidth = (((_c = (_b = columnRef.value) === null || _b === void 0 ? void 0 : _b.offsetWidth) !== null && _c !== void 0 ? _c : 0) -
            columns.value
                .map(x => x.width)
                .filter(isTruthy)
                .reduce((sum, current) => sum + current, 0)) /
            (columns.value.filter(x => !x.width).length || 1);
        for (const col of columns.value) {
            col.width = ((_d = col.width) !== null && _d !== void 0 ? _d : frameWidth) || 100;
        }
        nextColumn = columns.value[columns.value.findIndex(x => x.field === column.field) + 1] || undefined;
        document.addEventListener('mouseup', resizeEnd, { once: true });
        document.addEventListener('mousemove', resizeMove);
    }
    function resizeEnd() {
        document.removeEventListener('mousemove', resizeMove);
        cursorX = 0;
        resizeColumn = undefined;
    }
    function resizeMove(event) {
        window.requestAnimationFrame(() => {
            var _a, _b;
            let diff = cursorX - event.pageX;
            cursorX = event.pageX;
            if (!resizeColumn)
                return;
            const oldColumnWidth = (_a = resizeColumn.width) !== null && _a !== void 0 ? _a : 0;
            resizeColumn.width =
                oldColumnWidth - diff > minColumnSize && (nextColumn ? oldColumnWidth + diff > minColumnSize : true)
                    ? oldColumnWidth - diff
                    : oldColumnWidth;
            // Фикс бага, когда делаешь размер колонки меньше minColumnSize она перестает увеличиваться
            if (resizeColumn.width <= minColumnSize + 1) {
                resizeColumn.width = minColumnSize * 2;
                diff -= minColumnSize;
            }
            onResizeMove();
            if (!nextColumn || props.unfixedWidth)
                return;
            const nextColumnWidth = (_b = nextColumn.width) !== null && _b !== void 0 ? _b : 0;
            nextColumn.width =
                nextColumnWidth + diff > minColumnSize && resizeColumn.width - diff > minColumnSize
                    ? nextColumnWidth + diff
                    : nextColumnWidth;
        });
    }
    return resizeStart;
}
