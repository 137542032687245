import { defineComponent, h, resolveComponent } from 'vue';
const renderAlert = (alert, removeAlert) => {
    var _a;
    return h(resolveComponent('v-modal'), {
        modelValue: true,
        caption: alert.caption,
        classes: alert.classes,
        width: (_a = alert.width) !== null && _a !== void 0 ? _a : 200,
        height: alert.height,
        footerButtons: ['ok'],
        centrallize: true,
        'onClick:ok': () => removeAlert(alert),
    }, () => typeof alert.body === 'string'
        ? h('p', { class: alert.innerClasses, textContent: alert.body })
        : h(alert.body, { config: alert }));
};
const renderConfirm = (confirm, removeConfirm) => {
    var _a;
    return h(resolveComponent('v-modal'), {
        modelValue: true,
        caption: confirm.caption,
        classes: confirm.classes,
        width: (_a = confirm.width) !== null && _a !== void 0 ? _a : 350,
        height: confirm.height,
        footerButtons: ['ok', 'cancel'],
        centrallize: true,
        'onClick:ok': () => removeConfirm(confirm, true),
        'onClick:cancel': () => removeConfirm(confirm, false),
        onHidden: () => removeConfirm(confirm, false),
    }, () => typeof confirm.body === 'string'
        ? h('p', { class: confirm.innerClasses, textContent: confirm.body })
        : h(confirm.body, { config: confirm }));
};
const renderScreenLoader = (loaders) => h(resolveComponent('v-modal'), {
    modelValue: true,
    width: 350,
    centrallize: true,
    caption: 'Пожалуйста, подождите'
}, () => h('div', [loaders.map(x => h('p', x)), h(resolveComponent('v-loader'), { small: true })]));
export const DialogsContainerComponent = defineComponent({
    render() {
        return h('div', { class: 'v-alerts-container', 'data-alerts': this.$store.dialogs.alerts.length }, [
            ...this.$store.dialogs.alerts.map(({ alert }) => renderAlert(alert, this.$store.dialogs.removeAlert)),
            ...this.$store.dialogs.confirms.map(({ confirm }) => renderConfirm(confirm, this.$store.dialogs.removeConfirm)),
            this.$store.dialogs.screenLoaders.length > 0 ? renderScreenLoader(this.$store.dialogs.screenLoaders.map(x => x.text)) : undefined
        ]);
    },
});
