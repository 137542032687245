import { inject } from 'vue';
import { services } from '.';
/*
Example:
            const {alert} = services;
            alert({
                body: markRaw(
                    defineComponent({
                        props: ['config'],
                        render() {
                            return h(Page, { name: 'v-ui-guide' });
                        },
                    }),
                ),
                width: 1700,
            }).then(x => log.dev.debug('alert', x));
*/
function alert(cfg) {
    var _a;
    if (!cfg || !cfg.body)
        cfg = { body: cfg };
    const alert = cfg;
    const store = (_a = services.store) !== null && _a !== void 0 ? _a : inject('store');
    return store.dialogs.addAlert(alert);
}
export default alert;
