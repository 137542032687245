var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { services } from './index';
import { ref, computed } from 'vue';
import { api } from '@/services/api';
import { isEqual, cloneDeep } from 'lodash';
export class EntityStoreWrapper {
    constructor() {
        this.inner = (entityTypeName, configs) => useEntityStore(entityTypeName, configs);
    }
}
export const useEntityStore = (entityType, configs) => {
    const _entityType = ref(entityType);
    const _configs = ref(configs);
    const entity = ref({});
    const entityProxy = ref({});
    const load = (entityId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const [error, [result] = [null]] = yield api.data.get({
            entityTypeName: _entityType.value,
            filters: [{ field: 'key', value: entityId, operator: '=' }],
            converters: (_a = _configs.value) === null || _a === void 0 ? void 0 : _a.converters,
            relations: (_b = _configs.value) === null || _b === void 0 ? void 0 : _b.relations,
        });
        if (!result) {
            services.toast.error('Ошибка загрузки сущности');
            return;
        }
        entity.value = result;
        entityProxy.value = cloneDeep(entity.value);
        if (error)
            services.toast.error(error);
    });
    const save = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        updateRelationKeys();
        const [error, key = null] = yield api.data.save({
            entityTypeName: _entityType.value,
            entity: entity.value,
            converters: (_c = _configs.value) === null || _c === void 0 ? void 0 : _c.converters,
        });
        if (!key) {
            services.toast.error('Ошибка сохранения');
            return false;
        }
        if (error)
            services.toast.error(error);
        services.toast.success('Сохранено');
        yield load(key);
        return true;
    });
    const deleteEntity = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!entity.value.key)
            return;
        const [error] = yield api.data.delete({
            entityTypeName: _entityType.value,
            entityId: entity.value.key,
        });
        if (error)
            services.toast.error(error);
        if (!error)
            services.toast.success('Удалено');
    });
    const newEntity = () => {
        entity.value = {};
        entityProxy.value = {};
    };
    const updateRelationKeys = () => {
        var _a;
        if (!((_a = _configs.value) === null || _a === void 0 ? void 0 : _a.relations))
            return;
        _configs.value.relations.forEach(relation => {
            var _a;
            if (relation.type === 'single') {
                const relationEntity = entity.value[relation.relationField];
                entity.value[relation.from] = (_a = relationEntity === null || relationEntity === void 0 ? void 0 : relationEntity.key) !== null && _a !== void 0 ? _a : null;
            }
        });
    };
    const hasChanges = computed(() => !isEqual(entity.value, entityProxy.value));
    return {
        entity: entity,
        entityProxy: entityProxy,
        load,
        save,
        delete: deleteEntity,
        new: newEntity,
        hasChanges,
    };
};
