import { dateTime } from '@/utils/formaters';
import { DateTime } from 'luxon';
export function formatCellValue(entity, fieldName, formatter, defaultValue = '') {
    const value = entity[fieldName];
    if (formatter)
        return formatter(value, entity);
    if (value === null || value === undefined)
        return defaultValue;
    switch (value.constructor) {
        case Number:
            return value.toLocaleString();
        case Boolean:
            return value ? 'Да' : 'Нет';
        case DateTime:
            return dateTime(value);
        default:
            return value;
    }
}
