import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "placeholder", "readonly", "disabled", "required", "rows", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_v_button = _resolveComponent("v-button");
    return (_openBlock(), _createElementBlock("fieldset", {
        class: _normalizeClass(_ctx.mergeClass(_ctx.rootClasses, _ctx.$attrs.class, _ctx.name)),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        (!_ctx.noLabel)
            ? (_openBlock(), _createElementBlock("legend", {
                key: 0,
                "aria-hidden": "true",
                class: _normalizeClass(_ctx.labelClasses),
                for: _ctx.id
            }, _toDisplayString(_ctx.label), 11, _hoisted_1))
            : _createCommentVNode("", true),
        _createElementVNode("textarea", _mergeProps({
            id: _ctx.id,
            placeholder: _ctx.placeholder,
            readonly: _ctx.readonly,
            disabled: _ctx.disabled,
            required: _ctx.required,
            rows: _ctx.mutableRows && _ctx.maxRows && _ctx.mutableRows > _ctx.maxRows ? _ctx.maxRows : _ctx.mutableRows
        }, _ctx.attributes, { value: _ctx.mutableValue }), null, 16, _hoisted_2),
        (_ctx.counter)
            ? (_openBlock(), _createElementBlock("label", {
                key: 1,
                "aria-hidden": "true",
                class: _normalizeClass(_ctx.name + '__counter')
            }, _toDisplayString(`${(_a = (_ctx.mutableValue && _ctx.mutableValue.length)) !== null && _a !== void 0 ? _a : 0}${_ctx.counter !== true ? '/' + _ctx.counter : ''}`), 3))
            : _createCommentVNode("", true),
        (!(_ctx.readonly || _ctx.disabled) && _ctx.clearable && _ctx.mutableValue)
            ? (_openBlock(), _createBlock(_component_v_button, {
                key: 2,
                "aria-label": "Очистить",
                class: _normalizeClass(_ctx.name + '__clear'),
                onClick: _ctx.clear
            }, null, 8, ["class", "onClick"]))
            : _createCommentVNode("", true)
    ], 6));
}
