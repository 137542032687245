import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_multi_select = _resolveComponent("v-multi-select");
    return (_openBlock(), _createBlock(_component_v_multi_select, {
        modelValue: _ctx.modelValue,
        options: _ctx.options,
        label: _ctx.label,
        "onUpdate:modelValue": _cache[0] || (_cache[0] =
            value => {
                _ctx.$emit('update:modelValue', value);
            })
    }, null, 8, ["modelValue", "options", "label"]));
}
