import TabContainerComponent from './v-tab-container/v-tab-container.vue';
import ButtonComponent from './v-button/v-button';
import ModalComponent from './v-modal/v-modal.vue';
import InputComponent from './v-input/v-input.vue';
import FileInputComponent from './v-file-input.vue';
import SelectComponent from './v-select.vue';
import CheckboxComponent from './v-checkbox.vue';
import PopupComponent from './v-popup.vue';
import DataTableComponent from './v-data-table/v-data-table.vue';
import PaginationComponent from './v-pagination.vue';
import DataTableFilterComponent from './v-data-table/v-data-table-filter.vue';
import PickListComponent from './v-picklist.vue';
import CalendarComponent from './v-calendar.vue';
import DateTimePickerComponent from './v-date-time-picker.vue';
import LoaderComponent from './v-loader.vue';
import MultiSelectComponent from './v-multi-select.vue';
import MultiPickListComponent from './v-multi-picklist.vue';
import TextAreaComponent from './v-textarea.vue';
import LookupComponent from './v-lookup.vue';
//import AttachmentComponent from './v-attachment.vue';
import PaginatedDataTableComponent from './v-paginated-data-table.vue';
export const BaseComponents = {
    install(app) {
        app.component('v-tab-container', TabContainerComponent);
        app.component('v-button', ButtonComponent);
        app.component('v-modal', ModalComponent);
        app.component('v-input', InputComponent);
        app.component('v-file-input', FileInputComponent);
        app.component('v-select', SelectComponent);
        app.component('v-multi-select', MultiSelectComponent);
        app.component('v-picklist', PickListComponent);
        app.component('v-multi-picklist', MultiPickListComponent);
        app.component('v-checkbox', CheckboxComponent);
        app.component('v-popup', PopupComponent);
        app.component('v-data-table', DataTableComponent);
        app.component('v-pagination', PaginationComponent);
        app.component('v-data-table-filter', DataTableFilterComponent);
        app.component('v-calendar', CalendarComponent);
        app.component('v-date-time-picker', DateTimePickerComponent);
        app.component('v-loader', LoaderComponent);
        app.component('v-textarea', TextAreaComponent);
        app.component('v-lookup', LookupComponent);
        //app.component('v-attachment', AttachmentComponent);
        app.component('v-paginated-data-table', PaginatedDataTableComponent);
    },
};
