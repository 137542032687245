import { defineStore } from 'pinia';
export const useToastStore = defineStore({
    id: 'toast',
    state: () => ({ toasts: [] }),
    actions: {
        clear() {
            this.toasts = [];
        },
        add(toast) {
            if (toast.onTop)
                this.toasts.unshift(toast);
            else
                this.toasts.push(toast);
            if (!toast.delay || toast.delay > 0)
                setTimeout(() => {
                    this.remove(toast);
                }, toast.delay && toast.delay > 0 ? toast.delay : 2000);
        },
        remove(toast) {
            for (let i = 0; i < this.toasts.length; i++) {
                if (this.toasts[i].guid === toast.guid) {
                    this.toasts.splice(i, 1);
                    break;
                }
            }
        },
    },
});
