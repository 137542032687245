import { defineFunctionalComponent } from '@/utils/render';
import { defineComponent, h, TransitionGroup } from 'vue';
const ToastComponent = defineFunctionalComponent({
    guid: String,
    body: String,
    width: { type: String, default: '220px' },
    classes: {
        type: [String, Array, Object],
    },
    onClick: Function,
}, ({ classes, guid, body, width, onClick }) => h('div', {
    key: guid,
    class: ['v-toast', classes],
    style: width,
    role: 'alert',
    onClick,
}, h('div', { class: 'toast-body', onClick, innerHTML: body })));
export const ToastContainerComponent = defineComponent({
    render() {
        const toasts = this.$store.toast.toasts;
        const click = (toast) => {
            if (toast.on.click) {
                toast.on.click(toast, () => this.$store.toast.remove(toast));
            }
            if (toast.dismissible && !toast.on.click)
                this.$store.toast.remove(toast);
        };
        return h('div', { class: 'v-toast-container', style: 'z-index: 1100' }, h(TransitionGroup, { name: 'toast-fade', tag: 'div' }, () => toasts.map(toast => h(ToastComponent, {
            ref: toast.guid,
            key: toast.guid,
            width: toast.width,
            guid: toast.guid,
            body: toast.body,
            classes: toast.classes,
            onClick: () => click(toast),
        }))));
    },
});
