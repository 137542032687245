import { defineComponent, h } from 'vue';
import { PageComponent } from '@/components/base/v-page';
import { services } from '@/services';
export const ModuleDialogsContainerComponent = defineComponent({
    render() {
        return h('div', { class: 'v-module-dialog-container' }, [
            ...services.store.moduleDialog.modules.map(({ moduleName, props }) => h(PageComponent, Object.assign({ moduleName: moduleName, onDispose: () => services.store.moduleDialog.removeModule(moduleName) }, props))),
        ]);
    },
});
