import { inject } from 'vue';
import { services } from '.';
function confirm(cfg) {
    var _a;
    if (!cfg || !cfg.body)
        cfg = { body: cfg };
    const confirm = cfg;
    const store = (_a = services.store) !== null && _a !== void 0 ? _a : inject('store');
    return store.dialogs.addConfirm(confirm);
}
export default confirm;
