import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "v-toolbar__item",
                onClick: item.onClick
            }, _toDisplayString(item.label), 9, _hoisted_1));
        }), 128))
    ]));
}
