import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_loader = _resolveComponent("v-loader");
    const _component_v_select = _resolveComponent("v-select");
    const _component_v_input = _resolveComponent("v-input");
    const _component_v_modal = _resolveComponent("v-modal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_loader, {
                key: 0,
                small: ""
            }))
            : (_openBlock(), _createBlock(_component_v_select, {
                key: 1,
                modelValue: _ctx.modelValue,
                options: _ctx.options,
                label: _ctx.label,
                showClearButton: _ctx.showClearButton,
                showAddButton: _ctx.acceptAdd,
                "onUpdate:modelValue": _cache[0] || (_cache[0] =
                    value => {
                        _ctx.$emit('update:modelValue', value);
                    }),
                "onClick:add": _ctx.onClickAdd
            }, null, 8, ["modelValue", "options", "label", "showClearButton", "showAddButton", "onClick:add"])),
        _createVNode(_component_v_modal, {
            modelValue: _ctx.modalVisible,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.modalVisible) = $event)),
            caption: _ctx.modalCaption,
            footerButtons: ['ok', 'cancel'],
            okButtonText: "Сохранить",
            width: 400,
            "onClick:ok": _cache[3] || (_cache[3] = ($event) => (_ctx.onClickSave())),
            "onClick:cancel": _cache[4] || (_cache[4] = ($event) => (_ctx.modalVisible = false))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_v_input, {
                    modelValue: _ctx.newItem,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.newItem) = $event)),
                    label: "Значение",
                    maxLength: 128
                }, null, 8, ["modelValue"])
            ]),
            _: 1
        }, 8, ["modelValue", "caption"])
    ], 64));
}
