import { ref } from 'vue';
export const useDrag = (initialLeft, initialTop) => {
    const left = ref(initialLeft);
    const top = ref(initialTop);
    function drag(d, e) {
        if (!d)
            return;
        d.classList.add('s-modal__modal--drag');
        const oX = Number.parseInt(d.style.left || '');
        const oY = Number.parseInt(d.style.top || '');
        const eX = xy(e);
        const eY = xy(e, true);
        let stop;
        document.onmousemove = document.ontouchmove = (e) => {
            if (stop)
                return;
            d.style.top = xy(e, true) + oY - eY + 'px';
            d.style.left = xy(e) + oX - eX + 'px';
        };
        document.onmouseup = document.ontouchend = () => {
            stop = 1;
            d.classList.remove('s-modal__modal--drag');
            document.onmousemove = document.ontouchmove = null;
            document.onmouseup = document.ontouchend = null;
            let leftLocal = Number.parseInt(d.style.left || '');
            let topLocal = Number.parseInt(d.style.top || '');
            if (topLocal < 0)
                topLocal = 0;
            if (leftLocal < 0)
                leftLocal = 0;
            if (leftLocal > window.innerWidth - 200)
                leftLocal -= 200;
            if (topLocal > window.innerHeight - 200)
                topLocal -= 200;
            d.style.top = `${topLocal}px`;
            d.style.left = `${leftLocal}px`;
            left.value = leftLocal;
            top.value = topLocal;
        };
    }
    return { left, top, drag };
};
function xy(e, isY) {
    var _a, _b;
    return isY ? ('pageY' in e ? e.pageY : (_a = e.touches[0]) === null || _a === void 0 ? void 0 : _a.pageY) : 'pageX' in e ? e.pageX : (_b = e.touches[0]) === null || _b === void 0 ? void 0 : _b.pageX;
}
