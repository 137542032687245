export function dataTableSort(items, allowSort, columns, sortFn) {
    if (!allowSort)
        return items;
    return [...items].sort((a, b) => {
        for (const col of columns
            .filter(x => x.sortIndex >= 0 && x.sortOrder != null && x.field)
            .sort((a, b) => a.sortIndex - b.sortIndex)) {
            const result = sortFn(a, b, col);
            if (!result)
                continue;
            return result;
        }
        return 0;
    });
}
export function sort(a, b, col) {
    const field = col.field;
    if (a[field] === b[field])
        return 0;
    const direction = col.sortOrder === 'desc' ? -1 : 1;
    if (((a[field] === null || a[field] === undefined) && b[field] === null) || b[field] === undefined)
        return 0;
    if (a[field] === null || a[field] === undefined)
        return direction;
    if (b[field] === null || b[field] === undefined)
        return -direction;
    return a[field] > b[field]
        ? direction
        : a[field] < b[field]
            ? -direction
            : 0;
}
