var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { nextTick, ref } from 'vue';
export function updatePosition(root, topLeftCorner, menuWidth = ref('auto')) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        let rect = (_a = root.value) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!rect || (rect.x === 0 && rect.y === 0)) {
            yield nextTick();
            rect = (_b = root.value) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        }
        if (!rect)
            return;
        const width = String(rect.width) + 'px';
        if (menuWidth.value !== width)
            menuWidth.value = width;
        if (topLeftCorner.top !== rect.bottom)
            topLeftCorner.top = rect.bottom;
        if (topLeftCorner.left !== rect.left)
            topLeftCorner.left = rect.left;
    });
}
