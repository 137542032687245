import { responseHandler } from './helpers';
export function callPostForm(path, parameters) {
    const formData = new FormData();
    Object.entries(parameters).forEach(x => formData.append(x[0], x[1]));
    return responseHandler(fetch(path, {
        method: 'POST',
        body: formData,
    }));
}
export function callPost(path, parameters) {
    return responseHandler(fetch(path, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(parameters),
    }));
}
export function callGet(path, parameters) {
    return responseHandler(fetch(path + '?' + new URLSearchParams(parameters), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }));
}
export function callDelete(path, parameters) {
    return responseHandler(fetch(path + '?' + new URLSearchParams(parameters), {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    }));
}
