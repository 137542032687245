export function validateNumber(type, value, min, max) {
    if (!type)
        return;
    if (value === undefined)
        return '';
    if (value === null || value === '')
        return value;
    if ((type === 'integer' || type === 'double' || type === 'percent') && value === '-') {
        return value;
    }
    const regExp = regExpByType(type);
    const number = type === 'hex' ? Number.parseInt(value, 16) : Number.parseFloat(value);
    if (regExp.test(value || '') &&
        (value === '-' || ((max === undefined || number <= max) && (min === undefined || number >= min)))) {
        return value;
    }
}
export const cutToRange = (value, min, max) => value <= min ? min : value >= max ? max : value;
export function regExpByType(type) {
    if (type instanceof RegExp)
        return type;
    switch (type) {
        case 'integer':
            return /^(-?[1-9]\d*|0)$/;
        case 'positive':
            return /^([1-9]\d*|0)$/;
        case 'double':
            return /^-?\d*[,.]?\d*$/;
        case 'hex':
            return /^[\da-f]*$/i;
        case 'percent':
            return /^-?\d*[,.]?\d*%?$/;
        case 'email':
            return /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;
    }
}
