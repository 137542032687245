import { defineStore } from 'pinia';
export const useDialogsStore = defineStore({
    id: 'dialogs',
    state: () => ({
        alerts: [],
        confirms: [],
        screenLoaders: [],
    }),
    actions: {
        addAlert(alert) {
            let resolve;
            const promise = new Promise(p => {
                resolve = p;
            });
            this.alerts.push({ alert, resolve: resolve, promise });
            return promise;
        },
        removeAlert(alert) {
            const record = this.alerts.find(x => x.alert === alert);
            record === null || record === void 0 ? void 0 : record.resolve(alert);
            this.alerts = this.alerts.filter(x => x !== record);
        },
        addConfirm(confirm) {
            let resolve;
            const promise = new Promise(p => {
                resolve = p;
            });
            this.confirms.push({ confirm, resolve: resolve, promise });
            return promise;
        },
        removeConfirm(confirm, result) {
            const record = this.confirms.find(x => x.confirm === confirm);
            record === null || record === void 0 ? void 0 : record.resolve(result);
            this.confirms = this.confirms.filter(x => x !== record);
        },
        addScreenLoader(text, promise) {
            this.screenLoaders.push({ text, promise });
            promise.then(() => this.removeScreenLoader(text));
            return promise;
        },
        removeScreenLoader(text) {
            const record = this.screenLoaders.find(x => x.text === text);
            this.screenLoaders = this.screenLoaders.filter(x => x !== record);
        }
    },
});
