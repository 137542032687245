import { defineComponent, h, resolveComponent, watch } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import { ToastContainerComponent } from '@/components/v-toast-container';
import { DialogsContainerComponent } from '@/components/v-dialogs-container';
import { ModuleDialogsContainerComponent } from '@/components/v-module-dialog-container';
import '@/styles/index.scss';
import { services } from '@/services';
import MasterPageComponent from './master-page.vue';
export default defineComponent({
    setup() {
        const router = useRouter();
        services.store.user.loadUser();
        watch(() => services.store.user.user, (user) => {
            if (user === null)
                router.push('/login');
        });
        return () => services.store.user.loading
            ? [h(resolveComponent('v-loader')), h(ToastContainerComponent)]
            : router.currentRoute.value.name === 'login'
                ? h(RouterView)
                : h('div', { class: 'app' }, [
                    h(MasterPageComponent),
                    h(ToastContainerComponent),
                    h(DialogsContainerComponent),
                    h(ModuleDialogsContainerComponent),
                ]);
    },
});
