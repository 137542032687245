import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "loading" };
const _hoisted_2 = {
    key: 0,
    class: "dot"
};
const _hoisted_3 = {
    key: 1,
    class: "dot"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(`dot${_ctx.small ? '--small' : ''}`)
        }, null, 2),
        _createElementVNode("div", {
            class: _normalizeClass(`dot${_ctx.small ? '--small' : ''}`)
        }, null, 2),
        _createElementVNode("div", {
            class: _normalizeClass(`dot${_ctx.small ? '--small' : ''}`)
        }, null, 2),
        (!_ctx.small)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : _createCommentVNode("", true),
        (!_ctx.small)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true)
    ]));
}
