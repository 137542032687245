import masker from '@/utils/mask/masker';
import { computed, ref } from 'vue';
export const useMaskInput = (props, { emit }, mutableRootClasses, validate) => {
    const mutableValue = ref(props.modelValue);
    const valueWrapper = undefined;
    const attributes = computed(() => ({
        onChange: (event) => {
            const target = event.target;
            if (!target) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            mutableValue.value = target.value;
            if (props.validation(mutableValue.value)) {
                emit('update:modelValue', mutableValue.value, event);
            }
        },
        onInput: (event) => {
            const target = event.target;
            if (!target) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            const initialLength = target.value.length;
            const caretPosition = target.selectionStart;
            if (props.mask) {
                target.value = masker(target.value, props.mask, true, props.tokens);
            }
            mutableValue.value = target.value;
            if (target.selectionStart === target.value.length) {
                const shift = target.value.length - initialLength;
                const shiftPosition = (caretPosition !== null && caretPosition !== void 0 ? caretPosition : 0) + shift;
                target.selectionStart = shiftPosition;
                target.selectionEnd = shiftPosition;
            }
            let validation = props.validation(mutableValue.value);
            if (props.readonly || props.disabled)
                validation = true;
            mutableRootClasses.value['error--text'] = validation !== true;
            if (props.detail !== validation) {
                emit('update:valid', validation === true);
            }
            emit('input', mutableValue.value, event);
        },
        onPaste: (event) => {
            var _a, _b, _c;
            if (props.readonly || props.disabled || props.preventPaste) {
                event.preventDefault();
                return;
            }
            const target = event.target;
            if (!target)
                return;
            const clipdata = (_b = (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('Text')) !== null && _b !== void 0 ? _b : '';
            if (target.selectionEnd !== null && target.selectionStart !== null) {
                target.value = masker(target.value.slice(0, target.selectionStart) +
                    clipdata +
                    target.value.slice(target.selectionEnd, target.value.length), (_c = props.mask) !== null && _c !== void 0 ? _c : null, true, props.tokens);
            }
            validate(target.value);
            mutableValue.value = target.value;
            event.preventDefault();
            emit('update:modelValue', mutableValue.value);
            emit('paste', mutableValue.value, event);
        },
        onClick: () => {
            emit('click');
        },
    }));
    return { attributes, valueWrapper };
};
