import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "master-page" };
const _hoisted_2 = { class: "navbar-container" };
const _hoisted_3 = { class: "toolbar-container" };
const _hoisted_4 = { class: "router-view-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_navbar = _resolveComponent("v-navbar");
    const _component_v_toolbar = _resolveComponent("v-toolbar");
    const _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_navbar)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_toolbar)
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_view)
        ])
    ]));
}
