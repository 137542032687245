import { createGuid } from '@/utils/createGuid';
import { isNotNullOrUndefined } from '@/utils/guards';
import { mergeClass } from '@/utils/render';
import { cloneDeep, noop } from 'lodash';
import { computed, defineComponent, h, nextTick, onMounted, onUpdated, ref, watch, } from 'vue';
import { formatCellValue } from './format-cell-value';
import { useDataTableResize } from './use-data-table-resize';
const StyleComponent = (_, context) => h('style', context.attrs, context.slots);
export default defineComponent({
    inheritAttrs: false,
    emits: [
        'update:modelValue',
        'update:detailsId',
        'update:columns',
        'row:dblclick',
        'row:click',
        'update:filters',
        'update:clearFilters',
        'mounted:body',
    ],
    components: { 'v-style': StyleComponent },
    props: {
        modelValue: { type: Array, default: () => [] },
        idField: { type: String, required: true },
        columns: { type: Array, required: true },
        items: { type: Array, required: true },
        selectionMode: {
            type: String,
            default: null,
        },
        detailsId: { type: String },
        loading: { type: Boolean, default: false },
        preventRowClick: { type: Boolean, default: false },
        scrollToDetails: { type: Boolean, default: true },
        showHeader: { type: Boolean, default: true },
        allowResize: { type: Boolean, default: false },
        allowSort: { type: Boolean, default: false },
        unfixedWidth: { type: Boolean, default: false },
        sort: { type: Function, default: sort },
        highlightedRows: { type: WeakSet },
    },
    setup(props, { emit, attrs, slots }) {
        var _a;
        const tableId = (_a = attrs.id) !== null && _a !== void 0 ? _a : 'g' + createGuid().split('-').join('');
        const hasScroll = ref(false);
        const body = ref();
        const lastSelectedEntity = ref();
        const details = [];
        const columnRef = ref();
        onMounted(() => {
            if (!body.value)
                return;
            hasScroll.value = body.value.scrollHeight > body.value.clientHeight;
        });
        onUpdated(() => {
            nextTick(() => {
                if (!body.value)
                    return;
                hasScroll.value = body.value.scrollHeight > body.value.clientHeight;
            });
        });
        const visibleColumns = computed(() => props.columns.filter(x => x.visible !== false));
        const resizeStart = useDataTableResize(visibleColumns, props, columnRef, noop);
        const visibleItems = computed(() => sortData(props.items, props.allowResize, props.columns, props.sort));
        function getId(entity) {
            return entity === null || entity === void 0 ? void 0 : entity[props.idField];
        }
        function getHeaderClasses(column, index) {
            /* eslint-disable @typescript-eslint/naming-convention */
            return {
                'v-data-table__header-cell': true,
                'v-data-table__header-cell--sortable': column.sortable !== false && props.allowSort,
                'v-data-table__header-cell--resizable': props.allowResize,
                [`v-data-table__header-cell--${index}`]: true,
            };
            /* eslint-enable @typescript-eslint/naming-convention */
        }
        function rowDblClick(entity) {
            emit('row:dblclick', entity);
        }
        function showDetail(entity, entityId) {
            const id = entityId !== null && entityId !== void 0 ? entityId : getId(entity);
            if (id === props.detailsId || !isNotNullOrUndefined(id)) {
                emit('update:detailsId', undefined);
            }
            else
                emit('update:detailsId', id);
        }
        function rowClick(entity, event) {
            if (props.preventRowClick) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (slots.details)
                showDetail(entity);
            if (props.modelValue.includes(entity)) {
                if (props.selectionMode === 'multiple' && !event.ctrlKey) {
                    emit('update:modelValue', []);
                }
                else {
                    emit('update:modelValue', props.modelValue.filter(x => x !== entity));
                }
            }
            else {
                switch (props.selectionMode) {
                    case 'single':
                        emit('update:modelValue', [entity]);
                        break;
                    case 'multiple':
                        if (event.shiftKey) {
                            const entityIndex = visibleItems.value.indexOf(entity);
                            const lastSelectedEntityIndex = visibleItems.value.indexOf(lastSelectedEntity.value);
                            const selectedEntities = [];
                            for (let i = Math.min(entityIndex, lastSelectedEntityIndex); i <= Math.max(entityIndex, lastSelectedEntityIndex); i++) {
                                const e = visibleItems.value[i];
                                if (!props.modelValue.includes(e))
                                    selectedEntities.push(e);
                            }
                            if (selectedEntities.length) {
                                emit('update:modelValue', [...props.modelValue, ...selectedEntities]);
                            }
                        }
                        else if (event.ctrlKey) {
                            emit('update:modelValue', [...props.modelValue, entity]);
                        }
                        else {
                            emit('update:modelValue', [entity]);
                        }
                        break;
                    default:
                        break;
                }
                lastSelectedEntity.value = entity;
            }
            emit('row:click', entity);
        }
        function toggleSort(column) {
            var _a;
            if (!props.allowSort || column.sortable === false)
                return;
            const columns = cloneDeep(props.columns);
            for (const col of columns) {
                if (col.field === column.field) {
                    col.sortOrder = column.sortOrder === 'asc' ? 'desc' : 'asc';
                    col.sortIndex = 0;
                    continue;
                }
                col.sortOrder = null;
                col.sortIndex = -1;
            }
            emit('update:columns', columns);
            (_a = body.value) === null || _a === void 0 ? void 0 : _a.scroll(0, 0);
        }
        watch(() => props.detailsId, () => {
            if (!props.scrollToDetails ||
                !body.value ||
                !details[0] ||
                body.value.scrollTop + body.value.offsetHeight >= details[0].offsetTop + details[0].offsetHeight) {
                return;
            }
            body.value.scrollTo(0, details[0].offsetTop + details[0].offsetHeight - body.value.offsetHeight);
        });
        return {
            tableId,
            hasScroll,
            visibleItems,
            visibleColumns,
            details,
            body,
            columnRef,
            getId,
            getAlign,
            getHeaderClasses,
            formatCellValue,
            rowClick,
            rowDblClick,
            showDetail,
            toggleSort,
            resizeStart,
            mergeClass,
        };
    },
});
function getAlign(align) {
    switch (align) {
        case 'top':
        case 'left':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'bottom':
        case 'right':
            return 'flex-end';
    }
}
function sort(a, b, col) {
    const field = col.field;
    if (a[field] === b[field])
        return 0;
    const direction = col.sortOrder === 'desc' ? -1 : 1;
    if (((a[field] === null || a[field] === undefined) && b[field] === null) || b[field] === undefined) {
        return 0;
    }
    if (a[field] === null || a[field] === undefined)
        return direction;
    if (b[field] === null || b[field] === undefined)
        return -direction;
    return a[field] > b[field]
        ? direction
        : a[field] < b[field]
            ? -direction
            : 0;
}
function sortData(items, allowSort, columns, sortFn) {
    if (!allowSort)
        return items;
    return [...items].sort((a, b) => {
        for (const col of columns
            .filter(x => x.sortIndex >= 0 && x.sortOrder != null && x.field)
            .sort((a, b) => a.sortIndex - b.sortIndex)) {
            const result = sortFn(a, b, col);
            if (!result)
                continue;
            return result;
        }
        return 0;
    });
}
