import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "v-input-control" };
const _hoisted_2 = { class: "v-input-control-inner" };
const _hoisted_3 = { class: "v-input-control-inner" };
const _hoisted_4 = ["id", "readonly", "disabled", "value"];
const _hoisted_5 = ["for"];
const _hoisted_6 = {
    key: 0,
    class: "v-input-details"
};
const _hoisted_7 = { class: "v-input-details-inner" };
const _hoisted_8 = { class: "v-input-details-inner" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["v-checkbox", _ctx.mergeClass(_ctx.rootClasses, _ctx.$attrs.class)]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "left"),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("input", _mergeProps({
                        id: _ctx.id,
                        "aria-checked": "false",
                        role: "checkbox",
                        type: "checkbox",
                        readonly: _ctx.readonly || _ctx.disabled,
                        disabled: _ctx.readonly || _ctx.disabled
                    }, _ctx.attributes, { value: _ctx.modelValue }), null, 16, _hoisted_4),
                    _renderSlot(_ctx.$slots, "icon", {
                        value: _ctx.modelValue,
                        switchValue: _ctx.switchValue,
                        checkboxClasses: _ctx.checkboxClasses
                    }, () => [
                        _createElementVNode("i", {
                            "aria-hidden": "true",
                            class: _normalizeClass(["v-icon", _ctx.checkboxClasses]),
                            onClick: _cache[0] || (_cache[0] =
                                //@ts-ignore
                                (...args) => (_ctx.switchValue && _ctx.switchValue(...args)))
                        }, null, 2)
                    ])
                ]),
                _renderSlot(_ctx.$slots, "default", {}, () => [
                    (_ctx.label)
                        ? (_openBlock(), _createElementBlock("label", {
                            key: 0,
                            "aria-hidden": "true",
                            class: _normalizeClass(["v-label label", { 'label--required': _ctx.required }]),
                            for: _ctx.id,
                            onClick: _cache[1] || (_cache[1] =
                                //@ts-ignore
                                (...args) => (_ctx.switchValue && _ctx.switchValue(...args)))
                        }, _toDisplayString(_ctx.label), 11, _hoisted_5))
                        : _createCommentVNode("", true)
                ])
            ]),
            (_ctx.detailMessage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.detailMessage), 1)
                    ])
                ]))
                : _createCommentVNode("", true)
        ])
    ], 6));
}
