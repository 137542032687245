import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    ref: "root",
    class: "v-toolbar"
};
const _hoisted_2 = { class: "v-toolbar__button-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_popup = _resolveComponent("v-popup");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
                class: "v-toolbar__button",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.switchMenuVisible('create')))
            }, "Создать..."),
            _createElementVNode("div", {
                class: "v-toolbar__button",
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.switchMenuVisible('export')))
            }, "Экспорт...")
        ]),
        _createVNode(_component_v_popup, {
            visible: _ctx.visible,
            "onUpdate:visible": _cache[2] || (_cache[2] = ($event) => ((_ctx.visible) = $event)),
            "top-left-corner": _ctx.topLeftCorner,
            class: "v-toolbar__popup",
            excludedElements: { root: _ctx.root }
        }, {
            default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.popupContent[_ctx.currentMenu])))
            ]),
            _: 1
        }, 8, ["visible", "top-left-corner", "excludedElements"])
    ], 512));
}
