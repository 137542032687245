import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "v-modal__content" };
const _hoisted_2 = { class: "v-modal__footer" };
const _hoisted_3 = { class: "v-modal__footer-buttons" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_button = _resolveComponent("v-button");
    const _component_v_popup = _resolveComponent("v-popup");
    return (_openBlock(), _createBlock(_component_v_popup, {
        visible: _ctx.visible,
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event) => ((_ctx.visible) = $event)),
        class: _normalizeClass(["v-modal", _ctx.rootClasses]),
        preventClickOutsideHide: ""
    }, {
        default: _withCtx(() => [
            _createElementVNode("section", {
                ref: "modalRef",
                class: "v-modal__modal",
                style: _normalizeStyle({
                    left: _ctx.left + 'px',
                    top: _ctx.top + 'px',
                    width: _ctx.width + 'px',
                    height: _ctx.height + 'px',
                })
            }, [
                _createElementVNode("header", {
                    class: _normalizeClass(["v-modal__header", { 'v-modal__header--draggable': _ctx.draggable }]),
                    onMousedown: _cache[0] || (_cache[0] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.mouseDownOnHeader && _ctx.mouseDownOnHeader(...args)), ["exact"])),
                    onTouchstartPassive: _cache[1] || (_cache[1] = ($event) => (_ctx.draggable ? _ctx.drag(_ctx.modalRef, $event) : undefined))
                }, [
                    _renderSlot(_ctx.$slots, "header", {}, () => [
                        _createTextVNode(_toDisplayString(_ctx.caption), 1)
                    ])
                ], 34),
                _createElementVNode("main", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _createElementVNode("footer", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "footer", {}, () => [
                        _createElementVNode("div", _hoisted_3, [
                            (_ctx.footerButtons.includes('ok'))
                                ? (_openBlock(), _createBlock(_component_v_button, {
                                    key: 0,
                                    disabled: _ctx.okButtonDisabled,
                                    primary: "",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('click:ok')))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.okButtonText), 1)
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]))
                                : _createCommentVNode("", true),
                            (_ctx.footerButtons.includes('cancel'))
                                ? (_openBlock(), _createBlock(_component_v_button, {
                                    key: 1,
                                    secondary: "",
                                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('click:cancel')))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
                                    ]),
                                    _: 1
                                }))
                                : _createCommentVNode("", true)
                        ])
                    ])
                ])
            ], 4)
        ]),
        _: 3
    }, 8, ["visible", "class"]));
}
