import { isNotNullOrUndefined } from '@/utils/guards';
import { cutToRange, validateNumber } from './validators';
import { computed, ref } from 'vue';
export const numberTypes = ['integer', 'positive', 'double', 'percent'];
const isNumberType = (x) => numberTypes.includes(x);
export function saveMultiplyOn100(value) {
    if (typeof value === 'number')
        value = String(value);
    let index = value.indexOf('.');
    if (index < 0)
        return value + '00';
    let v = `${value.replace('.', '')}00`;
    const isNegative = v.startsWith('-');
    if (isNegative) {
        v = v.slice(1);
        index--;
    }
    v = v.slice(0, index + 2) + '.' + v.slice(index + 2);
    v = v.replace(/^0+/, '').replace(/(?<=[.1-9])0+$/, '');
    if (v.endsWith('.'))
        v = v.slice(0, -1);
    return isNegative ? '-' + v : v;
}
export const useNumberInput = (props, { attrs, emit }, mutableRootClasses, validate) => {
    if (!isNumberType(props.type))
        return { attributes: ref({}) };
    const separators = ['.', ','];
    const groupSeparator = ' ';
    const separatorRegexp = new RegExp('[' + separators.join('') + ']', 'g');
    const stringBeginning = new Set(['-', ...separators, ...separators.map(x => '-' + x)]);
    const valueWrapper = computed({
        get() {
            if (props.modelValue === null || props.modelValue === undefined) {
                return null;
            }
            if (!props.modelValue)
                return props.modelValue;
            if (mutableRootClasses.value['v-input--focused']) {
                return setDecimalView(String(props.type === 'percent' && typeof props.modelValue === 'number'
                    ? saveMultiplyOn100(props.modelValue)
                    : props.modelValue), separatorRegexp, props.decimalPlaces);
            }
            return Number.parseFloat(String(props.modelValue).replace(separatorRegexp, '.')) + props.measureStr;
        },
        set(value) {
            emit('update:modelValue', !value ? null : String(value).replace(groupSeparator, ''));
        },
    });
    const attributes = computed(() => ({
        onInput: (event) => {
            const target = event.target;
            if (!target)
                return;
            emit('input', target.value, event);
        },
        onChange: (event) => {
            const target = event.target;
            if (!target)
                return;
            if (stringBeginning.has(target.value))
                target.value = '';
            target.value =
                target.value === '' || target.value === null || target.value === undefined
                    ? ''
                    : String(cutToRange(Number(target.value.replace(separatorRegexp, '.')), Number(props.min), Number(props.max)));
            valueWrapper.value = setDecimalView(setPercentView(target.value, 1, props.type, separatorRegexp, props.numberFormatOptions), separatorRegexp, props.decimalPlaces);
            if (props.type === 'percent') {
                if (target.value === setPercentModel(target.value, props.type, separatorRegexp)) {
                    return;
                }
                emit('update:modelValue', target.value ? setPercentModel(target.value, props.type, separatorRegexp) : null);
                return;
            }
            emit('update:modelValue', setPercentModel(target.value, props.type, separatorRegexp), event);
        },
        onKeypress: (event) => preventKeyPress(event, props, separators),
        onPaste: (event) => {
            if (props.readonly || props.disabled || props.preventPaste || !isNumberType(props.type)) {
                event.preventDefault();
                return;
            }
            const target = event.target;
            if (!target)
                return;
            const clipdata = event.clipboardData ? event.clipboardData.getData('Text') : '';
            target.value =
                target.value.slice(0, target.selectionStart) +
                    clipdata +
                    target.value.slice(target.selectionEnd, target.value.length);
            if (attrs.maxLength) {
                target.value = target.value.slice(0, Number(attrs.maxLength));
            }
            if (validateNumber(props.regExp || props.type, target.value, Number(props.min), Number(props.max)) === undefined) {
                event.preventDefault();
                return false;
            }
            validate(target.value);
            setInputValue(target, props, separatorRegexp);
            event.preventDefault();
            emit('update:modelValue', Number(target.value.replace(separatorRegexp, '.')));
        },
    }));
    return { attributes, valueWrapper };
};
function setInputValue(target, props, separatorRegexp) {
    var _a;
    if (!isNumberType(props.type))
        return;
    const validatedValue = validateNumber(props.regExp || props.type, target.value) !== undefined ? target.value : target.defaultValue;
    target.value = (_a = setDecimalView(validatedValue, separatorRegexp, props.decimalPlaces)) !== null && _a !== void 0 ? _a : '';
    target.defaultValue = target.value;
}
function preventKeyPress($event, props, separators) {
    if (!$event.target)
        return;
    const key = $event.key;
    const selectionStart = $event.target.selectionStart;
    let value = props.modelValue;
    if (typeof value === 'number')
        value = String(value);
    switch (props.type) {
        case 'integer':
            if (((key < '0' || key > '9') && key !== '-') ||
                (!!value && key === '-' && (value.includes('-') || selectionStart !== 0))) {
                $event.preventDefault();
            }
            break;
        case 'positive':
            if (key < '0' || key > '9') {
                $event.preventDefault();
            }
            break;
        case 'double':
            if (((key < '0' || key > '9') && !separators.includes(key) && key !== '-') ||
                (!!value && key === '-' && (value.includes('-') || selectionStart !== 0)) ||
                (!!value && separators.includes(key) && value.includes(key))) {
                $event.preventDefault();
            }
            break;
        case 'percent':
            if (((key < '0' || key > '9') && !separators.includes(key) && key !== '-' && key !== '%') ||
                (!!value && key === '-' && (value.includes('-') || selectionStart !== 0)) ||
                (!!value && separators.includes(key) && value.includes(key)) ||
                (!!value && key === '%' && value.includes('%'))) {
                $event.preventDefault();
            }
            break;
    }
}
function setDecimalView(value, separatorRegexp, decimalPlaces) {
    if (typeof value === 'number')
        value = String(value);
    if (!isNotNullOrUndefined(value))
        return value;
    if (decimalPlaces > 20 ||
        decimalPlaces < 0 ||
        !isNotNullOrUndefined(decimalPlaces) ||
        Number.isNaN(Number(decimalPlaces))) {
        return value;
    }
    const lastIndex = value.replace(separatorRegexp, '.').lastIndexOf('.');
    if (lastIndex < 0)
        return value;
    const sumIndex = lastIndex + Number(decimalPlaces) + 1;
    return value.substring(0, Math.min(sumIndex, value.length));
}
function setPercentView(value, multiplier = 100, type, separatorRegexp, numberFormatOptions) {
    if (type !== 'percent' || value === null || value === undefined)
        return value;
    const number = Number.parseFloat(String(value).replace(separatorRegexp, '.'));
    if (Number.isNaN(number))
        return '0';
    return String(Number(number * multiplier).toFixed(numberFormatOptions.minimumFractionDigits === undefined ? 10 : numberFormatOptions.minimumFractionDigits));
}
function setPercentModel(value, type, separatorRegexp) {
    if (value === null || value === undefined || value === '')
        return null;
    if (type !== 'percent' || value == null)
        return value;
    const float = Number.parseFloat(String(value)
        .replace(separatorRegexp, '.')
        .replace(/[^\d.-]/g, ''));
    if (!value || !String(value) || Number.isNaN(float))
        return 0;
    return float / 100;
}
