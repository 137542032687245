import { DateTime } from 'luxon';
export const convertToString = (value) => {
    if (value === null || value === undefined)
        return null;
    switch (value.constructor) {
        case Number:
            return value.toString();
        case Boolean:
            return value ? '1' : '0';
        case DateTime:
            return value.toISO({ includeOffset: false, suppressMilliseconds: true });
        case Array:
            return JSON.stringify(value);
        default:
            return value;
    }
};
export const convertFieldsToString = (entity, converters) => {
    if (!converters)
        return entity;
    Object.entries(entity).forEach(entry => {
        const conv = converters.find(x => x.field === entry[0]);
        if (conv) {
            delete entity[entry[0]];
            entity[entry[0]] = convertFieldToString(entry[1], conv.type);
        }
    });
    return entity;
};
export const convertFieldsToType = (entity, converters) => {
    if (!converters)
        return entity;
    Object.entries(entity).forEach(entry => {
        const conv = converters.find(x => x.field === entry[0]);
        if (conv) {
            delete entity[entry[0]];
            entity[entry[0]] = convertFieldToType(entry[1], conv.type);
        }
    });
    return entity;
};
const convertFieldToType = (value, type) => {
    switch (type) {
        case 'StringArray':
            return JSON.parse(value);
        case 'DateTime':
            return DateTime.fromISO(value, { zone: 'utc' });
        case 'DateTime | null':
            return value === null ? null : DateTime.fromISO(value);
        case 'Boolean':
            return value === '1';
        default:
            return value;
    }
};
const convertFieldToString = (value, type) => {
    switch (type) {
        case 'StringArray':
            return JSON.stringify(value);
        case 'DateTime':
            return value.toISO({ suppressMilliseconds: true, includeOffset: false });
        case 'DateTime | null':
            return value === null
                ? null
                : value.toISO({ suppressMilliseconds: true, includeOffset: false });
        case 'Boolean':
            return value ? '1' : '0';
        default:
            return value;
    }
};
