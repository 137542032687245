import { useDialogsStore } from './dialogs';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useToastStore } from './toast';
import { useDynamicModules } from './use-dynamic-modules';
import { useUserStore } from './user';
import { useModuleDialogsStore } from './moduleDialogs';
const defaultTitle = ['base', 'route'];
export const useGlobalStore = defineStore('global', () => {
    const title = ref(defaultTitle);
    const description = ref('');
    const dynamic = useDynamicModules();
    const toast = useToastStore();
    const user = useUserStore();
    const dialogs = useDialogsStore();
    const moduleDialog = useModuleDialogsStore();
    return { title, description, defaultTitle, dynamic, dialogs, moduleDialog, toast, user };
});
