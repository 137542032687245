import { renderSlot as _renderSlot, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_Transition, { name: _ctx.transitionName }, {
        default: _withCtx(() => [
            (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                (_ctx.directiveIf)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                        key: 0,
                        ref: "root",
                        class: "v-popup"
                    }, _ctx.$attrs, {
                        onVnodeMounted: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('menu:mounted', _ctx.$refs.root)))
                    }), [
                        _renderSlot(_ctx.$slots, "default")
                    ], 16)), [
                        [_vShow, _ctx.directiveShow]
                    ])
                    : _createCommentVNode("", true)
            ]))
        ]),
        _: 3
    }, 8, ["name"]));
}
