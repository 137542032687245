import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "v-tab-container__tablist-container" };
const _hoisted_2 = {
    class: "v-tab-container__tablist",
    role: "tablist"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
    class: "v-tab-container__tab-detail",
    role: "tabpanel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["v-tab-container", { 'v-tab-container--subparagraph': _ctx.subparagraph }])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: _normalizeClass(["v-tab-container__tab", {
                                'v-tab-container__tab--active': tab.key === _ctx.modelValue,
                            }]),
                        role: "tab",
                        onClick: ($event) => (_ctx.$emit('update:modelValue', tab.key))
                    }, [
                        _createElementVNode("span", null, _toDisplayString(tab.title), 1)
                    ], 10, _hoisted_3));
                }), 128))
            ])
        ]),
        _createElementVNode("div", _hoisted_4, [
            (!_ctx.noTransition)
                ? (_openBlock(), _createBlock(_Transition, {
                    key: 0,
                    name: _ctx.tabTransition
                }, {
                    default: _withCtx(() => [
                        (_ctx.currentTab)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: _ctx.currentTab.key,
                                class: "v-tab v-tab--transition"
                            }, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTab.component), {
                                    key: _ctx.currentTab.key
                                }))
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["name"]))
                : (_ctx.currentTab)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: _ctx.currentTab.key,
                        class: "v-tab"
                    }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTab.component), {
                            key: _ctx.currentTab.key
                        }))
                    ]))
                    : _createCommentVNode("", true)
        ])
    ], 2));
}
