import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "v-list-viewer" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_loader = _resolveComponent("v-loader");
    const _component_v_tab_container = _resolveComponent("v-tab-container");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_loader, { key: 0 }))
            : (_openBlock(), _createBlock(_component_v_tab_container, {
                key: 1,
                modelValue: _ctx.currentTab,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.currentTab) = $event)),
                tabs: _ctx.tabs
            }, null, 8, ["modelValue", "tabs"]))
    ]));
}
