import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "v-date-time-picker__main-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_v_input = _resolveComponent("v-input");
    const _component_v_calendar = _resolveComponent("v-calendar");
    const _component_v_popup = _resolveComponent("v-popup");
    return (_openBlock(), _createElementBlock("div", {
        ref: "root",
        class: _normalizeClass(["v-date-time-picker", _ctx.$attrs.class]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _renderSlot(_ctx.$slots, "default", {
            value: _ctx.modelValue,
            show: _ctx.show
        }, () => [
            _createVNode(_component_v_input, {
                label: _ctx.label,
                readonly: _ctx.readonly,
                disabled: _ctx.disabled,
                buttons: _ctx.buttons,
                "model-value": _ctx.dateTimeView,
                placeholder: _ctx.placeholder,
                detail: _ctx.detail,
                bordered: _ctx.bordered,
                required: _ctx.required,
                type: "mask",
                mask: "##.##.####",
                onInput: _ctx.onInput,
                onPaste: _ctx.onInput,
                onClick: _cache[0] || (_cache[0] = e => _ctx.showOnInputClick && _ctx.show()),
                onFocus: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('focus', $event))),
                onKeypress: _withKeys(_ctx.onApply, ["enter"])
            }, null, 8, ["label", "readonly", "disabled", "buttons", "model-value", "placeholder", "detail", "bordered", "required", "onInput", "onPaste", "onKeypress"])
        ]),
        _createVNode(_component_v_popup, {
            visible: _ctx.visible,
            "top-left-corner": _ctx.topLeftCorner,
            "transition-name": "v-date-time-picker__menu-transition",
            class: _normalizeClass((_a = _ctx.popupClass) !== null && _a !== void 0 ? _a : 'v-date-time-picker__popup')
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_v_calendar, {
                        modelValue: _ctx.calendarSelectedValue,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.calendarSelectedValue) = $event)),
                        maxDateTime: _ctx.maxDateTime,
                        minDateTime: _ctx.minDateTime,
                        monthSelectClass: _ctx.monthSelectClass,
                        yearButtonSpread: _ctx.yearButtonSpread,
                        dateOnly: _ctx.dateOnly,
                        hideYearPicker: _ctx.hideYearPicker,
                        timepickerMenuClass: _ctx.timepickerMenuClass,
                        "onClick:cancel": _cache[3] || (_cache[3] = ($event) => (_ctx.setPopupVisible(false))),
                        "onClick:apply": _ctx.onApply
                    }, null, 8, ["modelValue", "maxDateTime", "minDateTime", "monthSelectClass", "yearButtonSpread", "dateOnly", "hideYearPicker", "timepickerMenuClass", "onClick:apply"])
                ])
            ]),
            _: 1
        }, 8, ["visible", "top-left-corner", "class"])
    ], 6));
}
