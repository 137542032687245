import { h, mergeProps } from 'vue';
import './v-button.scss';
const classAttributes = [
    'primary',
    'secondary',
    'single',
    'action',
    'icon',
    /** color attributes */
    'disabled',
    'success',
    'critic',
    'warning',
    'black',
];
const ButtonComponent = (props, context) => {
    var _a, _b, _c;
    return h('button', mergeProps({
        class: [
            'btn',
            ...classAttributes.filter(x => props[x] !== undefined && props[x] !== false).map(x => `btn--${x}`),
        ],
        type: 'button',
        disabled: props.disabled,
    }, context.attrs), (typeof props.icon === 'string' && props.icon.length) || (typeof props.action === 'string' && props.action.length)
        ? [h('i', { class: (_a = props.icon) !== null && _a !== void 0 ? _a : props.action }), (_c = (_b = context.slots).default) === null || _c === void 0 ? void 0 : _c.call(_b)]
        : context.slots);
};
ButtonComponent.props = classAttributes;
ButtonComponent.inheritAttrs = false;
export default ButtonComponent;
