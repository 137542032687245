import { omit } from 'lodash';
import { convertFieldsToString, convertToString } from './converters';
const getDataRequestTemplate = () => ({
    fields: [],
    filters: [],
    sort: null,
    count: null,
    rowOffset: 0,
    getTotalResults: false,
    relations: [],
});
const getDataRequest = (from) => {
    var _a, _b, _c, _d;
    return (Object.assign(Object.assign(Object.assign({}, getDataRequestTemplate()), omit(from, ['converters', 'converters', 'formatters'])), { filters: (_b = (_a = from.filters) === null || _a === void 0 ? void 0 : _a.map(x => (Object.assign(Object.assign({}, x), { value: convertToString(x.value) })))) !== null && _b !== void 0 ? _b : [], relations: (_d = (_c = from.relations) === null || _c === void 0 ? void 0 : _c.map(x => omit(x, ['relationField', 'type']))) !== null && _d !== void 0 ? _d : [] }));
};
const saveDataRequest = (from) => ({
    entityJson: JSON.stringify(convertFieldsToString(from.entity, from.converters)),
});
const deleteDataRequest = (from) => ({
    entityJson: JSON.stringify({ key: from.entityId }),
});
export default { getDataRequest, saveDataRequest, deleteDataRequest };
