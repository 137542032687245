import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "v-paginated-data-table" };
const _hoisted_2 = { class: "v-paginated-data-table__toolbar" };
const _hoisted_3 = { class: "v-paginated-data-table__toolbar__panel" };
const _hoisted_4 = { class: "v-paginated-data-table__toolbar__search-panel" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_input = _resolveComponent("v-input");
    const _component_v_pagination = _resolveComponent("v-pagination");
    const _component_v_data_table = _resolveComponent("v-data-table");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "toolbar")
            ]),
            _createElementVNode("div", _hoisted_4, [
                (_ctx.searchPanel)
                    ? (_openBlock(), _createBlock(_component_v_input, {
                        key: 0,
                        modelValue: _ctx.searchString,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.searchString) = $event)),
                        placeholder: "Поиск",
                        onInput: _cache[1] || (_cache[1] =
                            value => {
                                _ctx.searchString = value;
                            })
                    }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
            ])
        ]),
        _createVNode(_component_v_data_table, _mergeProps(Object.assign(Object.assign({}, _ctx.$attrs), _ctx.$props), {
            externalSort: "",
            items: _ctx.visibleItems
        }), {
            footer: _withCtx(() => [
                (_ctx.length > 1)
                    ? (_openBlock(), _createBlock(_component_v_pagination, {
                        key: 0,
                        modelValue: _ctx.page,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.page) = $event)),
                        length: _ctx.length,
                        totalVisible: _ctx.totalVisible
                    }, null, 8, ["modelValue", "length", "totalVisible"]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 16, ["items"])
    ]));
}
