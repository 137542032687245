import { createInjector } from 'typed-inject';
import toast from './toast';
import useLocale from '@/utils/locale/useLocale';
import confirm from './confirm';
import alert from './alert';
import screenLoader from './screen-loader';
import moduleDialog from './moduleDialog';
const serviceProvider = createInjector()
    .provideValue('store', undefined)
    .provideValue('toast', toast)
    .provideValue('local', useLocale)
    .provideValue('toast', toast)
    .provideValue('confirm', confirm)
    .provideValue('alert', alert)
    .provideValue('screenLoader', screenLoader)
    .provideValue('moduleDialog', moduleDialog);
export const getContainer = (injector) => {
    let provider = injector;
    return new Proxy({}, {
        get: (_, key) => (key !== 'provider' ? provider.resolve(key) : provider),
        set: (_, property, value) => {
            if (property !== 'provider')
                return false;
            if (!value || !('resolve' in value) || !('provideValue' in value)) {
                return false;
            }
            provider = value;
            return true;
        },
    });
};
export const services = getContainer(serviceProvider);
