import { createGuid } from '@/utils/createGuid';
import { inject, reactive } from 'vue';
import { services } from '.';
function toast(cfg) {
    var _a;
    if (!cfg || !cfg.body)
        cfg = { body: cfg };
    const { body, classes, delay, width, onTop, dismissible = true, on = {} } = cfg;
    const toast = reactive({
        guid: createGuid(),
        body,
        classes,
        delay,
        width,
        onTop,
        dismissible,
        on,
    });
    const store = (_a = services.store) !== null && _a !== void 0 ? _a : inject('store');
    store.toast.add(toast);
    return ({ body, classes, width, close, on } = {}) => {
        if (body !== undefined)
            toast.body = body;
        if (classes !== undefined)
            toast.classes = classes;
        if (width !== undefined)
            toast.width = width;
        if (on !== undefined)
            Object.assign(toast.on, on);
        if (close)
            store.toast.remove(toast);
    };
}
toast.error = (body) => toast({
    body: body !== null && body !== void 0 ? body : '',
    classes: 'critic',
});
toast.success = (body) => toast({ body, classes: 'success', delay: 1500 });
toast.warning = (body) => toast({ body, classes: 'warning', delay: 10000 });
toast.notSaved = () => toast({ body: 'Не удалось сохранить', classes: 'critic' });
toast.saved = () => toast({ body: 'Успешно сохранено', classes: 'success', delay: 800 });
export default toast;
