var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isTruthy } from '@/utils/guards';
import { convertFieldsToType } from './converters';
const responseHandler = (responsePromise) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield responsePromise;
    if (!response.ok)
        return [yield response.text(), undefined];
    if (response.status === 204)
        return [undefined, null];
    return parseResult(response);
});
const parseResult = (response) => __awaiter(void 0, void 0, void 0, function* () {
    const contentType = response.headers.get('Content-Type');
    if (contentType === undefined || (contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/json'))) {
        const result = yield response.text();
        return [undefined, (isTruthy(result) ? JSON.parse(result) : undefined)];
    }
    if (contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/octet-stream')) {
        const result = yield response.blob();
        return [undefined, result];
    }
    if (contentType === undefined || (contentType === null || contentType === void 0 ? void 0 : contentType.includes('text/plain'))) {
        const result = yield response.text();
        return [undefined, result];
    }
    return ['Ошибка при получении результата запроса', undefined];
});
const mapRelations = (data, relations, relationParameters, converters) => {
    if (relations.length > 0 && relationParameters.length > 0) {
        relationParameters.forEach(relation => {
            var _a, _b, _c;
            const entities = (_c = (_b = (_a = relations
                .find(x => x.entityName === relation.entityTypeName)) === null || _a === void 0 ? void 0 : _a.entities) === null || _b === void 0 ? void 0 : _b.map(x => convertFieldsToType(x, converters))) !== null && _c !== void 0 ? _c : undefined;
            if (entities !== undefined && relation.type === 'single') {
                data = data.map(ent => (Object.assign(Object.assign({}, ent), { [relation.relationField]: entities.find(rel => rel[relation.to] === ent[relation.from]) })));
            }
            if (entities !== undefined && relation.type === 'multiple') {
                data = data.map(ent => (Object.assign(Object.assign({}, ent), { [relation.relationField]: entities.filter(rel => rel[relation.to] === ent[relation.from]) })));
            }
        });
    }
    return data;
};
const formatData = (data, formatters = []) => data.map(entity => formatPredicate(entity, formatters));
const formatPredicate = (entity, formatters) => {
    const formattedEntity = entity;
    formatters.forEach(x => {
        formattedEntity[x.field] = x.formatFn(entity[x.field], entity);
    });
    return formattedEntity;
};
export { formatData, mapRelations, responseHandler };
