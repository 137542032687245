import { reactive } from 'vue';
const dynamicKeys = new Set(['has', 'wait', 'register', 'ctx', 'delete']);
export function useDynamicModules() {
    const dynamicModulesWaiters = new Map();
    return new Proxy(reactive(new Map()), {
        get: function (ctx, name) {
            if (name === 'ctx')
                return ctx;
            if (name === 'has')
                return (name) => ctx.has(name);
            if (name === 'delete')
                return (name) => ctx.delete(name);
            if (name === 'register') {
                return (name, module) => {
                    if (dynamicKeys.has(name)) {
                        throw new Error(`Module with name '${name}' cannot be registered`);
                    }
                    if (!module || typeof module !== 'object' || !('$id' in module)) {
                        throw new Error('Unable to register non pinia module');
                    }
                    ctx.set(name, module);
                    const waiter = dynamicModulesWaiters.get(name);
                    if (waiter) {
                        waiter.resolve(module);
                        dynamicModulesWaiters.delete(name);
                    }
                };
            }
            if (name === 'wait') {
                return (name) => {
                    var _a;
                    if (dynamicModulesWaiters.has(name)) {
                        return (_a = dynamicModulesWaiters.get(name)) === null || _a === void 0 ? void 0 : _a.promise;
                    }
                    const promise = new Promise(resolve => dynamicModulesWaiters.set(name, { resolve }));
                    const waiter = dynamicModulesWaiters.get(name);
                    if (waiter)
                        waiter.promise = promise;
                    return promise;
                };
            }
            return ctx.get(name);
        },
    });
}
