import * as format from '@/utils/formaters';
import { useGlobalStore } from './store/store';
import { services } from './services';
const { toast, local, moduleDialog } = services;
export const global = {
    install(app) {
        const store = useGlobalStore();
        app.config.globalProperties.$store = store;
        app.config.globalProperties.$toast = toast;
        app.config.globalProperties.$format = format;
        app.config.globalProperties.$local = local;
        app.config.globalProperties.$alert = alert;
        app.config.globalProperties.$confirm = confirm;
        app.config.globalProperties.$moduleDialog = moduleDialog;
        app.provide('store', store);
        app.provide('toast', toast);
        app.provide('format', format);
        app.provide('local', local);
        app.provide('alert', alert);
        app.provide('confirm', confirm);
        app.provide('moduleDialog', moduleDialog);
        services.provider = services.provider.provideValue('store', store);
    },
};
