import { responseHandler } from './helpers';
export function upload(file, category = 'Others') {
    const formData = new FormData();
    formData.append('attachment', file, file.name);
    formData.append('category', category);
    return responseHandler(fetch('/api/attachment/save', {
        method: 'POST',
        body: formData,
    }));
}
